<template>
  <div class="tickets padding-x">
    <Heading noDecoration type="h3" text-align="center" class="tickets__title">
      Your codes for<br />
      {{ event.name }}
    </Heading>
    <Preloader :loading="isOrderLoading" />
    <div v-for="ticket in tickets" v-bind:key="ticket.id" class="tickets__list">
      <Code :ticket="ticket" :redirect="redirect" />
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import Preloader from "@/components/common/Preloader";
import Code from "./CodeCardNew";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserCodesList",
  props: {},
  components: {
    Preloader,
    Heading,
    Code,
  },
  data: () => ({
    isOrderLoading: false,
    noCodes: false,
    tickets: [],
  }),
  computed: {
    ...mapGetters({
      event: "getCurrentEventDetails",
    }),
  },
  mounted() {
    this.isOrderLoading = true;
    this.getOrder();

    if (
      !Object.keys(this.event).length ||
      this.event.id !== this.$route.params.id
    ) {
      this.isOrderLoading = true;
      this.getEvent(this.$route.params.id)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isOrderLoading = false;
        });
    }
  },
  methods: {
    ...mapActions(["getEvent", "GET"]),
    getOrder() {
      return this.GET({
        route: `/ticketing/public/event/${this.$route.params.id}/codes/${this.$route.params.token}`,
      })
        .then((resp) => {
          this.tickets = resp.data;
          // console.log("getOrder__", resp);
          this.isOrderLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isOrderLoading = false;
        });
    },
    redirect(ticket, action) {
      localStorage.removeItem("chosen-sponsor-code");

      localStorage.setItem(
        "codes-token",
        JSON.stringify(this.$route.params.token)
      );
      localStorage.setItem(
        "chosen-code",
        JSON.stringify({
          id: ticket.id,
          level: ticket.level,
          title: ticket.title,
          claimed: ticket.claimed,
          limit: ticket.limit,
          description: ticket.description,
        })
      );
      if (action === "invite") {
        this.$router.replace(`/${this.$route.params.id}/invite/${ticket.id}`);
      } else {
        this.$router.replace(
          `/${this.$route.params.id}/register-multiple/${ticket.id}`
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.tickets {
  padding-top: 108px;

  &__title {
    margin-bottom: 21px;
  }

  &__list {
    display: flex;
  }

  @media (min-width: $media-md) {
    &__title {
      margin-bottom: 83px;
    }
  }
}
</style>
