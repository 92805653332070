<template>
  <div class="tooltip">
    <slot />
    <span
      class="tooltiptext"
      :class="{
        top: top || (!top && !bottom && !right && !left),
        bottom: bottom,
        right: right,
        left: left,
      }"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: {
    text: {
      type: String,
      default: "",
    },
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  min-width: 150px;
  background-color: $lighter-gray;
  color: $white;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.top {
  // top is position by default
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
}

.bottom {
  top: 100%;
  left: 50%;
  margin-left: -75px;
}

.right {
  top: -5px;
  left: 155%;
}

.left {
  top: -5px;
  right: 155%;
}

.tooltip .tooltiptext.top::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $lighter-gray transparent transparent transparent;
}

.tooltip .tooltiptext.bottom::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent $lighter-gray transparent;
}

.tooltip .tooltiptext.right::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent $lighter-gray transparent transparent;
}
.tooltip .tooltiptext.left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent $lighter-gray;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
