<template>
  <div class="code" :class="{ sponsored: isSponsor }">
    <div
      class="code__container"
      :style="
        ticket.claimed === ticket.limit ? 'background: rgb(168 171 171);' : null
      "
    >
      <div class="code__wrapper">
        <span class="code__title"> {{ ticket.title }} </span>
        <div class="code__actions">
          <Tooltip
            :text="
              copied ? 'Code copied to clipboard' : 'Click to copy the code'
            "
          >
            <img
              src="../../assets/img/icons/copy.svg"
              alt="Copy"
              @click="copyCode(ticket.id)"
            />
          </Tooltip>
          <Tooltip
            :text="
              ticket.claimed === ticket.limit
                ? 'You can’t invite more attendees'
                : 'Click to invite attendees'
            "
          >
            <img
              src="../../assets/img/icons/message-add.svg"
              alt="Invite attendees icon"
              :class="{
                'code__disabled-img': ticket.claimed === ticket.limit,
              }"
              @click="redirect(ticket, 'invite')"
            />
          </Tooltip>
          <Tooltip
            :text="
              ticket.claimed === ticket.limit
                ? 'You can’t register more attendees'
                : 'Click to register attendees'
            "
          >
            <img
              src="../../assets/img/icons/user-add.svg"
              alt="Register attendees icon"
              :class="{
                'code__disabled-img': ticket.claimed === ticket.limit,
              }"
              @click="redirect(ticket, 'register')"
            />
          </Tooltip>
        </div>
      </div>

      <span class="code__description" v-if="isSponsor">
        These tickets are only for company representatives. Be careful sharing
        these codes.
      </span>

      <div class="code__wrapper code__wrapper--details">
        <div class="code__details">
          <span class="code__label">Code</span>
          <span class="code__value">
            {{ ticket.id.toUpperCase() }}
          </span>
        </div>
        <div class="code__details">
          <span class="code__label">Features</span>
          <span class="code__value">
            <TicketsFeaturesIcons :features="ticket.description" />
          </span>
        </div>
        <div class="code__details">
          <span class="code__label">Code</span>
          <span class="code__value">
            {{ ticket.claimed }}/{{
              ticket.limit > 100 ? "unlimited" : ticket.limit
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip";
import TicketsFeaturesIcons from "./TicketsFeaturesIcons";

export default {
  components: { TicketsFeaturesIcons, Tooltip },
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
    redirect: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    copied: false,
  }),
  computed: {
    isSponsor() {
      return this.ticket.level === "sponsor";
    },
  },
  methods: {
    async copyCode(code) {
      console.log(code);
      try {
        await navigator.clipboard.writeText(code);
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.code {
  border: 2px solid #ffffff33;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  min-width: 100%;
  width: 100%;
  max-width: unset;

  &.sponsored {
    border: 3px solid rgb(255, 255, 255);
  }

  &__container {
  }

  &__wrapper {
    display: flex;

    border-bottom: 1px solid #ffffff33;
    padding-bottom: 24px;
    margin-bottom: 12px;

    &--details {
      border-bottom: none;
    }
  }

  &__title {
    font-family: $Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: #ffffff;
    flex-grow: 1;
  }

  &__description {
    font-family: $Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #ffffff80;
    margin-bottom: 28px;
  }

  &__actions {
    display: flex;
    column-gap: 12px;
  }

  &__label {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #ffffff80;
    display: block;
    margin-bottom: 6px;
  }

  &__details {
    flex-basis: 33.3%;
    margin-top: 28px;
  }

  @media (min-width: $media-lg) {
    min-width: 100%;
  }
}
</style>
